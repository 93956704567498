
.auth_section{min-height: 630px;}
.auth_section .auth_form{max-width: 504px;margin: 0 auto;background: #f7f7f7;border-radius: 30px;padding: 48px;position: relative;margin-top: 200px;}
.auth_section .auth_form:after{content: '';background: url("../images/assets/form-shadow.png")no-repeat;background-size: 100%;width: 100%;height: 80px;position: absolute;z-index: -1;bottom: -15px;
left: 0;right: 0;margin: 0 auto;}
.auth_section .auth_form .title{text-align: center;margin-bottom: 22px;}
.auth_section .auth_form .title::after{content: '';background: url("../images/assets/pin.png") no-repeat;background-size: 50px;width: 50px;height: 50px;position: absolute;top: -20px;left: -20px;}
.auth_section .auth_form .title::before{content: '';background: url("../images/assets/tiger.png") no-repeat;background-size: 150px;width: 150px;height: 150px;position: absolute;top: -124px;right: 40px;}
.auth_section .auth_form .title-text{font-size: 45px;}
.auth_section .auth_form input{font-size: 18px;}
.auth_section .auth_form .page-subtitle, .auth_section .auth_form .another_page_text{font-size: 20px;}
.auth_section .auth_form form .forgot_text{color:  var(--success);font-size: 18px;line-height: 30px;text-align: center;margin-top: 25px;display: block;text-decoration: underline;}
.auth_section .another_page_text {color: var(--dark);text-align: center;margin-top: 32px;}

@media only screen and (max-width: 991px) {
.auth_section {min-height: 520px;}
.auth_section .auth_form .title-text {font-size: 38px;}
.auth_section .auth_form {padding: 40px;}
}

@media only screen and (max-width: 767px) {
.auth_section .auth_form{margin-top: 160px;padding: 32px;}
.auth_section .auth_form .title::before{background-size: 120px;width: 120px;height: 120px;top: -100px;}
}

@media only screen and (max-width: 575px) {
.auth_section {min-height: 450px;}
.auth_section .auth_form{margin-top: 150px;padding: 28px;border-radius: 20px;}
.auth_section .auth_form .title-text {font-size: 32px;}
.auth_section .auth_form .title::after{background-size: 40px;width: 40px;height: 40px;left: -10px;}
}

@media only screen and (max-width: 400px) {
.auth_section {min-height: 400px;}
.auth_section .auth_form .title-text {font-size: 28px;}
.auth_section .auth_form .page-subtitle, .auth_section .auth_form .another_page_text {font-size: 18px;}
}