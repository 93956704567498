.browse-books ul{list-style: none;padding: 0px;margin: 0px;flex-wrap: wrap;}
.browse-books ul li span {
  border-radius: 2rem;
  padding: 0.4rem 1.65rem;
  background-color: var(--primary);
  color: var(--white);
  box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5) inset;
  font-size: 20px;
  min-width: 83px;
  font-weight: normal;
  cursor: pointer;
} 
.browse-books ul li:not(:last-child) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.browse-books #books_all {
  padding-top: 12px;
  padding-bottom: 37px;
}
.book-card-item {
  margin-bottom: 56px;
}
.book-card {
  background-color: var(--white);
  border-color: #e3e3e3;
  padding: 0 28px;
}
.book-card .card-header {
  padding: 31px 0 19px;
  text-align: center;
}
.book-card .card-body {
  padding: 0 0 8px;
}
.book-card-image img {
  max-height: 220px;
  min-height: 220px;
  object-fit: cover;
}
.book-summary {
  margin: 26px 0 15px;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 20px;
}
.book-summary .row-devider > div:not(:first-child):after {
  background-color: var(--white);
}
.book-card p {
  margin-bottom: 10px;
}
.book-card .card-footer {
  padding: 0 0 31px;
}
.book-card .card-footer .btn img {
  margin-left: 3px !important;
}
@media only screen and (min-width: 768px) {
  .browse-books {
    padding-top: 44px;
  }
}