.offers-wrapper  .title{
    display:flex;
    align-items:center;
    justify-content:space-between;
    }

.header .boy-btn, .header .girl-btn{
    padding: 0.535rem 0.57rem 0.535rem 0rem;


}
.header .other-elem{
    align-items: center;
}

@media(max-width:768px){
    .offers-wrapper  .title{
        display: block;
    }
    .header .boy-btn, .header .girl-btn{
        margin-bottom: 10px;
    }
    }