/*
-----------------------------------------------------
	GENERAL
-----------------------------------------------------
*/
@font-face {
    font-family: 'PuddyGm-regular';
    src: url("fonts/PuddyGum-Regular.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap');

body {
    background-color: #fff;
    color: #5b5b5b;
    font-family: -apple-system, BlinkMacSystemFont, Schoolbell, Segoe UI, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.05rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Bubblegum Sans", sans-serif;
    font-weight: 600 !important;
    line-height: 1.1;
    margin-bottom: .5rem;
}

.btn {
    background-color: initial;
    border: 1px solid #0000;
    border-radius: .625rem;
    color: #555;
    display: inline-block;
    font-family: "Bubblegum Sans", sans-serif;
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 1.5;
    padding: .535rem 2.5rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
}
.header .boy-btn, .header .girl-btn {
    padding: 0.335rem .57rem 0.335rem 0!important;
}
.row-devider > div {
    position: relative;
}

.row-devider > div:not(:first-child):after {
    content: "";
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #e3e3e3;
    /* border-left: 1px solid rgba(0, 0, 0, 0.15);*/
}

.row-sm {
    margin-right: -10px;
    margin-left: -10px;
}

.row-sm > div[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
}

.row-devider > div:not(:first-child):after {
    content: "";
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.15);
    /* border-left: 1px solid rgba(0, 0, 0, 0.15);*/
}

.text-gray {
    color: #5b5b5b;
}

.text-xs {
    font-size: clamp(0.75rem, 0.3895rem + 0.5814vw, 0.875rem) !important;
}

.text-sm {
    font-size: clamp(0.875rem, 0.5145rem + 0.5814vw, 1rem) !important;
}

.text-base {
    font-size: clamp(1rem, 0.2791rem + 1.1628vw, 1.25rem) !important;
}

.text-lg {
    font-size: clamp(1.125rem, 0.4041rem + 1.1628vw, 1.375rem) !important;
}

.text-xl {
    font-size: clamp(1.25rem, 0.3488rem + 1.4535vw, 1.5625rem) !important;
}

.text-2xl {
    font-size: clamp(1.5rem, 0.4186rem + 1.7442vw, 1.875rem) !important;
}

.text-3xl {
    font-size: clamp(1.65rem, 0.2122rem + 3.4884vw, 3.125rem) !important;
}

.text-4xl {
    font-size: clamp(2rem, -0.6192rem + 5.2326vw, 3.75rem) !important;
}

.text-5xl {
    font-size: clamp(2.25rem, -0.4797rem + 5.814vw, 2.375rem) !important;
}

.text-6xl {
    font-size: clamp(3.75rem, 0.8663rem + 4.6512vw, 4.75rem) !important;
}

.font-thin {
    font-weight: 100;
}

.display-1 {
    font-size: clamp(3.125rem, -13.4549rem + 34.5865vw, 6rem);
}

.display-2 {
    font-size: clamp(2.5rem, -14.8008rem + 36.0902vw, 5.5rem);
}

.display-3 {
    font-size: clamp(1.875rem, -13.2632rem + 31.5789vw, 4.5rem);
}

.display-4 {
    font-size: clamp(1.5rem, -10.0338rem + 24.0602vw, 3.5rem);
}

.font-normal {
    font-weight: 400 !important;
}

.font-medium {
    font-weight: 500 !important;
}

.font-bold {
    font-weight: 600 !important;
}

.text-underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.no-underline,
.no-underline:hover {
    text-decoration: none;
}

p {
    font-size: 1.25rem;
}

.button-boy {
    background: #d1ecf7 !important;
    border-color: #d1ecf7 !important;
}

.button-girl:not(:disabled):not(.disabled).active:focus,
.button-girl:not(:disabled):not(.disabled):active:focus,
.show > .button-girl.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem #f0f1e3;
}

.button-boy:not(:disabled):not(.disabled).active,
.button-boy:not(:disabled):not(.disabled):active,
.show > .button-boy.dropdown-toggle {
    background-color: #d1ecf7;
    border-color: #d1ecf7;
    color: #5b5b5b;
}

.button-girl:not(:disabled):not(.disabled).active,
.button-girl:not(:disabled):not(.disabled):active,
.show > .button-girl.dropdown-toggle {
    background-color: #f0f1e3;
    border-color: #f0f1e3;
    color: #fff;
}

.button-girl {
    background: #f0f1e3 !important;
    border-color: #f0f1e3 !important;
}

.font-primary {
    font-family: "Schoolbell";
    font-weight: 600 !important;
}

.font-secondary {
    font-family: Bubblegum Sans, sans-serif;
    font-weight: 600;
}

.rounded-xl {
    border-radius: 0.625rem !important;
}

.rounded-full {
    border-radius: 9999px;
}

.btn {
    transition: all 0.25s ease-in-out 0s;
    box-shadow: 5px 4px 0 rgba(0, 0, 0, 0.11);
}

.btn img {
    margin-left: 0.65rem;
    width: 30px;
}
label {
    font-size: 1.125rem;
    color: #5b5b5b;
    margin-bottom: 14px;
}

.form-control,
.custom-select {
    box-shadow: 4px 4px 0px 0px rgb(0 0 0 / 11%);
    height: calc(1.78em + 1.15rem + 2px);
    border-radius: 0.625rem;
    border: 1px solid #e3e3e3;
}

.custom-select {
    background: #fff url("images/assets/arrow-down.svg") no-repeat right 15px center/30px 30px;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.11);
}

.btn-xs {
    font-size: 0.725rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.11);
}

.btn-sm {
    font-size: 0.85rem;
    padding: 0.5rem 0.85rem;
    border-radius: 0.5rem;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.11);
}

.btn-md {
    padding: 0.765rem 2.2rem;
}

.btn-lg {
    padding: 1rem 1.5rem;
}

.bg-primary-light {
    background-color: #f0f1e3 !important;
}

.border-radius-lg {
    border-radius: 2.5rem;
}

.bg-success {
    background-color: #d1ecf7 !important;
}

.text-white {
    color: #5b5b5b !important;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
    background-color: #d1ecf7;
    border-color: #d1ecf7;
    color: #fff;
}

.text-gradient {
    background: #f0f1e3;
    background: -moz-linear-gradient(54deg,
            #f0f1e3 0%,
            #f0f1e3 65%,
            #d1ecf7 100%);
    background: -webkit-linear-gradient(54deg,
            #f0f1e3 0%,
            #f0f1e3 65%,
            #d1ecf7 100%);
    background: linear-gradient(54deg, #f0f1e3 0%, #f0f1e3 65%, #d1ecf7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f1e3', endColorstr='#d1ecf7', GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: #d1ecf7;
    border-color: #d1ecf7;
    color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem #f0f1e3;
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem #d1ecf7;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
    background-color: #d1ecf7;
    border-color: #d1ecf7;
    color: #5b5b5b;
}


/*
  -----------------------------------------------------
	  HEADER
  -----------------------------------------------------
  */
.header,
.header * {
    transition: all 0.25s ease-in-out 0s;
    -webkit-transition: all 0.25s ease-in-out 0s;
    -moz-transition: all 0.25s ease-in-out 0s;
    -ms-transition: all 0.25s ease-in-out 0s;
    -o-transition: all 0.25s ease-in-out 0s;
    max-height: 112px;
}

.navbar-brand {
    max-width: 120px;
}

.navbar-light .navbar-nav .nav-item {
    padding: 8px 22px;
}

.navigation-wrapper .navbar-nav>.nav-item>.nav-link {
    font-size: 1.495rem;
    padding: .25rem .4rem;
    position: relative;
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link:after {
    background-color: #f0f1e3;
    bottom: 0;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    transition: all .25s ease-in-out 0s;
    width: 0;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #d1ecf7 !important;
    border-color: #d1ecf7 !important;
    color: #5b5b5b;
}

.navbar-light .navbar-nav .nav-link:hover:after,
.navbar-light .navbar-nav .nav-item.active .nav-link:after {
    width: 100%;
}

.user-elem .cart-link {
    margin-right: 22px;
}

.user-elem .cart-link img {
    width: 24px;
}

.cart-link {
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    color: #5b5b5b;
    font-size: 1.5rem;
}

.cart-link .badge {
    background-color: #d1ecf7;
    border-radius: 50%;
    color: #5b5b5b;
    display: inline-block;
    font-size: .875rem;
    height: 24px;
    line-height: 20px;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -10px;
    width: 24px;
}

.close-navbar-toggler.collapsed {
    z-index: -1;
}

/*
  ---------------------------------------------------
	   BANNER
  ---------------------------------------------------
   */
.banner-wrapper {
    position: relative;
    z-index: 2;
}

.banner-wrapper .carousel .carousel-item img {
    max-height: 445px;
    object-fit: fill;
    object-position: top;
}

.banner-caption {
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 100%;
    z-index: 10;
}

.banner-wrapper .carousel-control-prev,
.banner-wrapper .carousel-control-next {
    height: 76px;
    width: 76px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    bottom: 10%;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 100;
    background-color: rgba(34, 34, 34, 0.5);
    opacity: 1;
}

.banner-wrapper .carousel-control-next {
    right: 5rem;
    left: auto;
}

.banner-wrapper .carousel-control-prev-icon,
.banner-wrapper .carousel-control-next-icon {
    background: none;
}

.page-item.active .page-link {
    background-color: #d1ecf7 !important;
    border-color: #d1ecf7 !important;
    color: #5b5b5b;
    z-index: 3;
}

.page-link:focus {
    box-shadow: 0 0 0 .2rem #d1ecf782;
    outline: 0;
    z-index: 3;
}

.text-dark {
    color: #5b5b5b !important;
}

.banner-title {
    font-size: clamp(2.5rem, -5rem + 15vw, 1.25rem);
    background: #f0f1e3;
    background: -moz-linear-gradient(54deg,
            #f0f1e3 30%,
            #f0f1e3 50%,
            #d1ecf7 80%);
    background: -webkit-linear-gradient(54deg,
            #f0f1e3 30%,
            #f0f1e3 50%,
            #d1ecf7 80%);
    background: linear-gradient(54deg, #f0f1e3 30%, #f0f1e3 50%, #d1ecf7 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f1e3', endColorstr='#d1ecf7', GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    max-width: 550px;
}

.banner-sub-title {
    font-size: clamp(1.5rem, -4rem + 15vw, 2rem);
    font-family: "Schoolbell";
    color: #5b5b5b;
}

.btn-primary {
    background-color: #f0f1e3;
    border-color: #f0f1e3;
    color: #5b5b5b;
}

.page-title,
.title-text {
    -webkit-text-fill-color: #0000;
    background: #f0f1e3;
    background: #2d6fa3;
    -webkit-background-clip: text;
    display: inline-block;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d6fa3", endColorstr="#2d6fa3", GradientType=1);

    font-weight: 600;
}

section#child_tale .title-text.text-5xl.mb-0 {
    font-size: 3.3rem !important;
}

.title-text-lg {
    font-size: 2.5rem;
}

.page-subtitle {
    font-family: "Schoolbell";
    color: #5b5b5b;
}

/*
  -----------------------------------------------------
	  Children books
  -----------------------------------------------------
  */
.boy-btn,
.girl-btn {
    padding: 0.535rem 3.26rem;
}

/*
  -----------------------------------------------------
	  PROCESS
  -----------------------------------------------------
  */
.process-wrapper {
    background: #daedf7 url("images/background/process-bg.jpg") no-repeat 0 0/100%;
}


.process-item {
    position: relative;
    z-index: 2;
}

.process-item::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: -72px;
    background-color: #fff;
    height: 13px;
    width: 115%;
    z-index: -1;
}

.process-item:first-child:after {
    border-radius: 25px 0 0 25px;
}

.process-item:last-child:after {
    border-radius: 0 25px 25px 0;
    left: auto;
    right: 15px;
}

.process-card {
    background-color: var(--secondary);
    border-radius: .5rem;
    box-shadow: inset 2px 2px 3px #ffffff80;
    color: #5b5b5b;
    max-width: 251px;
    position: relative;
    z-index: 2;
}

/* .process-card::before {
	  content: "";
	  position: absolute;
	  bottom: -16px;
	  left: 50%;
	  transform: translateX(-50%) rotate(136deg);
	  background-color: inherit;
	  height: 38px;
	  width: 38px;
	  border-radius: 5px;
	  z-index: -1;
	} */
.arrow-down {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
}

.process-card::after {
    content: "";
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    background-color: inherit;
    height: 30px;
    width: 30px;
    font-family: Schoolbell;
    border-radius: 50%;
    box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5) inset;
}

.process-card {
    border-radius: 20px !important;
}

.process-item:first-child .process-card {
    background-color: #f9eaaf;
}

.process-item:nth-child(2) .process-card {
    background-color: #2d6fa3;
}

.process-item:nth-child(3) .process-card {
    background-color: #c4e7af;
}

.process-item:nth-child(4) .process-card {
    background-color: #eabbdb;
}

.process-number {
    position: relative;
    height: 89px;
    width: 85px;
    border-radius: 50%;
    color: #fff;
    font-size: clamp(2rem, -4.4878rem + 13.5338vw, 3.125rem) !important;
    margin: 6px auto 10px;
    background: url("images/assets/circles.png") no-repeat center center;
}

/* .process-number:before,.process-number:after{
	  content: "";
	  position: absolute;
	  height: 100%;
	  width: 100%;
	  border-radius: 50%;
	  border-top:5px solid var(--white);
	  top: -5px;
  }
  .process-number::after {
	  height: 96%;
	  width: 96%;
	  border-top: 0;
	  border-bottom: 5px solid var(--white);
	  bottom: -2px;
	  top: auto;
	} */
.process-title {
    line-height: 26px;
    color: #fff;
}

.promo-section {
    background: #fff url("images/background/promo-bg.png") no-repeat 50% / cover;
    margin-bottom: 68px;
    min-height: 410px;
    margin-top: 68px;
}

.promo-subtext {
    line-height: 35px;
}

.arrowiconS h4 {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Schoolbell, Segoe UI, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color emoji;
    font-weight: 600;
    line-height: 29px;
}

/*
  -----------------------------------------------------
	  FOOTER
  -----------------------------------------------------
  */
.footer {
    position: relative;
    z-index: 2;
}

.footer-top {
    background: #e5f8fc url("images/background/footer-bg.png") no-repeat top / cover;
    padding-top: 2.85rem;
    padding-bottom: 67px;
    position: relative;
}
.plane-img {
    position: absolute;
    top: -45px;
    right: calc(100vw - (90%));
    -webkit-animation: updown 4s linear infinite alternate;
    -moz-animation: updown 4s linear infinite alternate;
    -o-animation: updown 4s linear infinite alternate;
    animation: updown 4s linear infinite alternate;
}

.truck-img {
    position: absolute;
    bottom: -23px;
    right: calc(100vw - (16%));
    /*-webkit-animation: updown 4s linear infinite alternate;
  -moz-animation: updown 4s linear infinite alternate;
  -o-animation: updown 4s linear infinite alternate;
  animation: updown 4s linear infinite alternate;*/
}

@keyframes updown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-35px);
    }
}

.footer-copyright {
    background: #c4e7af;
}

.footer-copyright div[class*="container"] {
    position: relative;
}

.insect-img {
    position: absolute;
    top: -10px;
    left: 230px;
}

.footer-logo-widget img {
    max-width: 170px;
    margin: 0 auto;
}

.footer-widget-title {
    color: #5b5b5b;
    margin-bottom: 14px;
    font-size: 2.3rem !important;
    font-weight: 600;
}

.footer-widget-links .nav-item {
    margin-bottom: 0.15rem;
}

.footer-widget-links a {
    color: #5b5b5b;
    display: inline-block;
    font-size: 1.30rem;
    padding: 0;
}

.footer-widget-links a:hover {
    opacity: 0.5;
}

.social-networks {
    padding-top: 5px;
    padding-bottom: 36px;
}

.social-networks a {
    align-items: center;
    background-color: var(--white);
    border-radius: 50%;
    color: #5b5b5b;
    display: block;
    height: 38px;
    justify-content: center;
    line-height: 40px;
    margin-right: 1.4rem;
    text-align: center;
    width: 38px;
    margin-bottom: 1px;
}

.social-networks a:hover {
    background-color: var(--white);
    color: #f0f1e3;
    text-decoration: none;
}

.copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 1rem;
    margin-top: 1rem;
}

.explore-module {
    padding-bottom: 20px;
}

.explore-module .title {
    margin-bottom: 10px;
}

.browse-book-item {
    background-color: #f1f1f1;
    border-radius: 1.25rem;
    box-shadow: 4px 5px 0px rgba(0, 0, 0, 0.11);
    padding: 1.25rem;
    max-width: 514px;
}

.browse-book-carousel.owl-carousel .owl-nav > button[class*=owl-] {
    background-color: #2d6fa3;
    border-radius: 50%;
    color: var(--white);
    display: inline-block;
    font-size: 1.25rem;
    height: 57px;
    left: -3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 57px;
}

.browse-book-carousel.owl-carousel .owl-nav > button.owl-next {
    left: auto;
    right: 10px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: #f0f1e3;
    border-color: #f0f1e3;
    color: #5b5b5b;
}

.page-header {
    background: #fff url("images/background/page-header.jpg") no-repeat center 0 / cover;
    min-height: 234px;
    position: relative;
    z-index: 2;
    padding-top: 53px;
}

.browseBook-pageHeader.page-header {
    padding-top: 42px;
}

.browseBook-pageHeader .page-title {
    /* font-size: 3rem !important; */
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 34px;
    font-size: clamp(3.125rem, -1.561rem + 7.5581vw, 4.75rem) !important;
}

.page-title {
    font-size: clamp(3.125rem, -1.561rem + 7.5581vw, 4.75rem);
    margin-bottom: 21px;
    line-height: 0.8;
}

.page-subtitle {
    font-size: clamp(1.375rem, -0.4273rem + 2.907vw, 2rem);
    font-family: "Schoolbell";
    color: #5b5b5b;
}

.aboutus-wrapper {
    padding-top: 51px !important;
}

.fancy-card {
    background-color: #f1f1f1;
    border-radius: 1.5rem;
    padding: 1.5rem;
    box-shadow: 5px 5px 0px #e3e3e3;
}

.book-preview {
    position: relative;
    margin-bottom: 28px;
    padding-top: 52px;
}

.book-preview div[class*="container"] {
    position: relative;
}

/* .book-preview:before {
	content: "";
	position: absolute;
	top: -25px;
	background: #fff;
	height: 50px;
	width: 50px;
	left: 50%;
	border-radius: 5px;
	transform: translateX(-50%) rotate(136deg);
  } */
.book-preview div[class*="container"]:after {
    content: "";
    position: absolute;
    width: 157px;
    height: 155px;
    background: url("images/assets/quality-badge.png") no-repeat 0 0;
    right: 0;
    bottom: -40px !important;
    z-index: 9;
}

.bg-light-gradient {
    background: #e5f8fc;
    background: linear-gradient(180deg, #d1ecf7 0, #fff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5f8fc",endColorstr="#ffffff",GradientType=0);
}

.fancy-radio {
    padding-left: 3.25rem;
    margin: 0 3px;
}

.fancy-radio .custom-control-label::before,
.fancy-radio .custom-control-label::after {
    top: 0.25rem;
    width: 31px;
    height: 31px;
    left: -43px;
    border-color: #e3e3e3;
}

.custom-radio.fancy-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-2 -2 4 4'%3e%3ccircle r='2' fill='%2373d3f4'/%3e%3c/svg%3e");
}

.custom-radio.fancy-radio .custom-control-label::after {
    background: 50% / 66% 66% no-repeat;
}

.custom-radio.fancy-radio .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #fff;
    background-color: #fff;
    border-color: #e3e3e3;
}

.custom-radio.fancy-radio .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 144, 210, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(28, 144, 210, 0.25);
}

.browse-books ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    flex-wrap: wrap;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #5b5b5b !important;
}

.browse-books ul li span {
    background-color: #f0f1e3;
    border-radius: 2rem;
    box-shadow: inset 2px 2px 3px #ffffff80;
    color: #5b5b5b;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    min-width: 83px;
    padding: .4rem 1.65rem;
}

.browse-books ul li span.active {
    background-color: #d1ecf7 !important;
    color: #5b5b5b;
}

.offers-wrapper .card-title .text-dark {
    color: #2d6fa3 !important;
    height: 55px;
}

.browse-books ul li span.active {
    background-color: #d1ecf7;
    color: #5b5b5b;
}

.pagination .page-link {
    background-color: #f0f1e3 !important;
    color: #5b5b5b;
}

.browse-books ul li:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
}

.browse-books #books_all {
    padding-top: 12px;
    padding-bottom: 37px;
}

.book-card-item {
    margin-bottom: 56px;
}

.book-card {
    background-color: var(--white);
    border-color: #e3e3e3;
    padding: 0 28px;
}

.book-card .card-header {
    padding: 31px 0 19px;
    text-align: center;
}

.book-card .card-body {
    padding: 0 0 8px;
}

.book-card-image img {
    max-height: 220px;
    min-height: 220px;
    object-fit: cover;
}

.book-summary {
    margin: 26px 0 15px;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 20px;
}

.book-summary .row-devider > div:not(:first-child):after {
    background-color: var(--white);
}

.book-card p {
    margin-bottom: 10px;
}

.book-card .card-footer {
    padding: 0 0 31px;
}

.book-card .card-footer .btn img {
    margin-left: 3px !important;
}

.help-wrapper {
    padding-top: 36px;
    padding-bottom: 46px;
}

.sidebar-nav {
    max-width: 100%;
    width: 302px;
    margin-right: 33px;
    margin-top: 14px;
    border: 0;
}

.sidebar-nav .sidebar-nav-link {
    padding: 0.7rem 31px;
    background-color: #ddd;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    font-size: clamp(1.25rem, 0.8212rem + 0.8946vw, 1.568125rem);
    color: var(--white);
    box-shadow: 5px 5px 0px #e3e3e3;
    text-decoration: none;
    position: relative;
    max-height: 60px;
    transition: all 0.25s ease-in-out 0s;
    border: 0;
}

.sidebar-nav .sidebar-nav-link::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: var(--white);
    box-shadow: 3px 0px 0px rgba(0, 0, 0, 0.26) inset;
    transform: rotate(46deg);
}

.sidebar-nav .sidebar-nav-link::after {
    content: "";
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    height: 13px;
    width: 8px;
    background: url("images/assets/arrow-next-small.png") no-repeat 0 0/100%;
    /* font-family: "Font Awesome 5 Free";
	  font-weight: 900; */
}

.sidebar-nav .sidebar-nav-link:nth-child(1) {
    background-color: #f0f1e3;
    transform: rotate(-1.92deg);
}

.sidebar-nav .sidebar-nav-link:nth-child(2) {
    background-color: var(--success);
    transform: rotate(0.68deg);
}

.sidebar-nav .sidebar-nav-link:nth-child(3) {
    background-color: #50b5d8;
    transform: rotate(-3deg);
}

.sidebar-nav .sidebar-nav-link:nth-child(4) {
    background-color: var(--teal);
    transform: rotate(-2.25deg);
}

.sidebar-nav .sidebar-nav-link:nth-child(5) {
    background-color: #a5be25;
    transform: rotate(0);
}

.sidebar-nav .sidebar-nav-link:hover {
    transform: none;
}

.help-tab-content .help-content-module {
    margin-top: 18px;
}

.card-accordion {
    margin-bottom: 19px;
}

.accordion .card-header {
    border: 0;
    border-radius: 10px !important;
    padding: 0;
}

.accordion .card-header h4 {
    padding: 15px 29px 15px 29px;
    cursor: pointer;
}

.collapse_icons {
    margin-right: 9px;
}

.queIcon {
    position: relative;
}

.queIcon::after {
    content: "+";
    font-size: 24px;
    font-family: "Schoolbell";
    font-weight: 400;
    font-style: normal;
}

.queIcon.que-minus::after {
    content: "-";
}

.accordion .card-body {
    padding: 18px 15px 0 15px;
}

.previewBook-header .page-title {
    font-size: clamp(1.875rem, -0.8285rem + 4.3605vw, 2.8125rem);
    line-height: 55px;
}

.previewBook-warpper {
    padding-top: 48px;
}

.book-preview-carousel.owl-carousel {
    max-width: 810px;
    margin: 0 auto;
}

.book-preview-carousel.owl-carousel .owl-nav > button[class*="owl-"] {
    display: inline-block;
    background-color: rgba(34, 34, 34, 0.5);
    color: var(--white);
    border-radius: 50%;
    height: 48px;
    width: 48px;
}

.book-preview-item {
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.25);
}

.bookPreview-title {
    padding: 19px 0 18px;
}

.custom-switch .custom-control-label::before {
    width: 54px;
    height: 22px;
    border: 0;
    border-radius: 5rem;
}

.custom-switch .custom-control-label::after {
    width: 23px;
    height: 23px;
    border-radius: 5rem;
    top: 4px;
    left: calc(-37px + 1px);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(1.85rem);
    transform: translateX(1.85rem);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #d4edcd;
    background-color: #d4edcd;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #f0f1e3;
}

.custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.checkout-header.page-header {
    padding-top: 56px;
    background: url("images/background/checkout-page-header.png") no-repeat center 0 / cover;
    min-height: 187px;
    position: relative;
    z-index: 5;
}

.checkout-header .page-title {
    font-size: clamp(1.875rem, -0.8285rem + 4.3605vw, 2.8125rem);
    line-height: 55px;
}

.checkout-module {
    position: relative;
    z-index: 2;
    padding-top: 42px;
}

.checkout-module::after {
    content: "";
    position: absolute;
    right: 0;
    top: -25px;
    bottom: -51px;
    width: 45%;
    background-color: #f7f7f7;
    z-index: -1;
}

.checkout-form .form-group {
    margin-bottom: 28px;
}

.checkout-form {
    position: relative;
}

.checkout-summary {
    background-color: #f7f7f7;
    position: relative;
    z-index: 2;
}

.checkout-summary::after {
    content: "";
    position: absolute;
    left: 0;
    top: -100px;
    bottom: -51px;
    width: 45%;
    background-color: #f7f7f7;
    z-index: -1;
}

.table-cart {
    padding-top: 2px;
}

.table-cart table thead th {
    border: 0;
    padding: 0 5px 5px 0;
    color: #5b5b5b;
    font-size: 20px;
    font-weight: bold;
}

.table-cart table tbody td {
    padding: 24px 5px 28px 0;
    font-size: 20px;
    border-color: #e2e2e2;
    vertical-align: middle;
}

.table-cart table tbody tr:first-child td {
    padding: 5px 5px 28px 0;
    border: 0;
}

.table-cart table tbody td:last-child {
    font-weight: 600;
}

.table-cart table thead th:nth-child(1) {
    width: 152px;
}

.table-cart table thead th:nth-child(2) {
    width: 128px;
}

.table-cart table thead th:nth-child(3) {
    width: 103px;
}

.table-cart table thead th:nth-child(4) {
    width: 108px;
}

.table-cart table tfoot th {
    border-color: #e2e2e2;
    padding: 2px 5px 9px;
    font-size: 20px;
}

.table-cart table tfoot tr:first-child th {
    padding-top: 18px;
}

.table-cart table tfoot tr:not(:first-child) th {
    border: 0;
}

.table-cart table tfoot th:last-child {
    text-align: right;
    padding-right: 10px;
}

.coupon-input.form-row {
    margin-right: -7px;
    margin-left: -7px;
}

.coupon-input.form-row > [class*="col-"] {
    padding-right: 7px;
    padding-left: 7px;
}

.coupon-input .form-control {
    max-width: 157px;
    border-radius: 50px;
    height: calc(1em + 0.5rem + 3px);
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.11);
}

.coupon-input .btn {
    border-radius: 50px;
    font-size: 0.782rem;
    padding: 0.225rem 1.065rem;
    max-width: 75px;
    max-height: 27px;
    box-shadow: 5px 4px 0 rgba(0, 0, 0, 0.11);
}

.fancy-checkbox {
    padding-left: 42px;
}

.fancy-checkbox .custom-control-label::before,
.fancy-checkbox .custom-control-label::after {
    width: 22px;
    height: 22px;
    left: -42px;
    top: 3px;
}

.fancy-checkbox .custom-control-label::before {
    border-color: #ececec;
    box-shadow: inset 1px 0 5px #dddddd;
}

.cart-edit {
    margin-right: 3px;
}

.item-name small {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
}

.payment-information {
    padding-top: 25px;
}

.payment-title {
    margin-bottom: 3px;
}

.payment-subtitle {
    margin-bottom: 17px;
}

.payment-button-box {
    padding-top: 32px;
}

.btn-pay-now {
    padding: 0.82rem 2.8rem 0.725rem;
}

.header-light-bg,
.header-light-bg .navigation-wrapper {
    background-color: #f0f9f7 !important;
}

.name-book-card {
    max-width: 593px;
}

.name-book-carousel.owl-theme .owl-dots,
.name-book-carousel.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 20px;
    max-height: 20px;
}

.border-danger {
    border-color: #f0f1e3 !important;
}

a {
    background-color: initial;
    color: #2d6fa3;
    text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #f0f1e3;
    color: #5b5b5b;
    text-decoration: none;
}

.name-book-carousel.owl-theme .owl-dots .owl-dot span {
    height: 16px;
    width: 16px;
    background-color: #888888;
    margin: 0;
}

.name-book-carousel.owl-theme .owl-dots .owl-dot {
    padding: 0 0.156rem !important;
}

.name-book-carousel.owl-theme .owl-dots .owl-dot.active span,
.name-book-carousel.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #f0f1e3;
}

.name-book-wrap .title {
    margin-bottom: 10px;
}

.inner-page-wrapper .card-title h4 {
    color: #2d6fa3 !important;
    font-size: 1.6rem;
    font-weight: 600;
    height: 55px;
}

.name-book-summary:hover {}

.name-book-summary {
    padding: 8px 0 28px;
}

.name-book-wrapper {
    padding-bottom: 43px;
}

.selection-module-wrapper {
    padding-bottom: 0px;
}

.selection-module-title {
    margin-bottom: 12px;
}

.selection-module-controls {
    padding-top: 8px;
}

.navbar-toggler-icon {
    position: relative;
    height: 3px;
    width: 28px;
    border-radius: 5rem;
    background-image: none;
    background-color: #f0f1e3;
    transition: all 0.35s ease-in-out 0s;
}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    content: "";
    width: 100%;
    height: 3px;
    border-radius: 5rem;
    background-color: #f0f1e3;
    position: absolute;
    left: 0;
    top: -8px;
    transform-origin: right;
    transition: all 0.35s ease-in-out 0s;
}

.navbar-toggler-icon:after {
    top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    top: 12px;
}

.subscribe_now .subscribe_view {
    display: grid;
    grid-template-columns: .8fr 1fr;
    padding: 30px;
    background: #dbedf7;
    border-radius: 20px;
    align-items: center;
}

.subscribe_now .subscribe_view .subscibe_form {
    padding-left: 60px;
}

.subscribe_now .subscribe_view .subscibe_form form {
    display: flex;
    width: 100%;
}

.subscribe_now .subscribe_view .subscibe_form form .form-group {
    width: 100%;
    margin: 0px;
}

.subscribe_now .subscribe_view .subscibe_form form .payment-button-box button {
    display: flex;
    height: 56px;
    padding: 0 30px;
    line-height: 56px;
    margin-left: 20px;
    align-items: center;
}

.subscribe_now .subscribe_view .subscibe_form form .payment-button-box {
    padding-top: 0;
}

.search-box .dropdown-menu {
    width: 320px;
    padding: 10px 20px 10px 10px;
    border-radius: 10px;
    right: 0;
    left: auto;
    top: 70px;
}

.search-box .dropdown-menu .search {
    display: flex;
    align-items: center;
}

.search-box .dropdown-menu .search input {
    height: 50px;
    margin-right: 20px;
}

.search-box .dropdown-menu form .list-group {
    max-height: 300px;
    overflow: auto;
}

.subscribe_now .subscribe_view .subscribe_content p {
    margin: 0px;
}

.privacy-section .content p {
    word-wrap: break-word;
}

.other-elem .dropdown-menu.show {
    max-height: 150px !important;
}

.hi-user a {
    color: #5b5b5b;
    font-size: 17px;
}

.demoPage {
    background-color: #ddd;
    transform: rotateY(5deg) translateX(2px);
    box-shadow: -5px -5px 15px rgba(0, 0, 0, .25);
    transform-origin: left;
}

.demoPage.--right {
    transform: rotateY(-5deg) translateX(-2px);
    box-shadow: -5px -5px 15px rgba(0, 0, 0, .25);
    transform-origin: right;
}

.bookImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, .8);
}

.bookImage2 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, .8);
}

.demoPage.--right .bookImage {
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, .8);
}

#ajaxLoader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.2);
}

#ajaxLoader span {
    width: 250px;
    background: #fff;
    border-radius: 5px;
    margin: 0 auto;
    float: none;
    padding: 20px;
    display: inherit;
    margin-top: 10%;
    font-size: 18px;
}

.arrowiconS {
    text-align: center;
    margin: 10px auto 30px;
}

.arrowiconS div {
    display: inline;
    cursor: pointer;
}

.arrowiconS .first-pg img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.bookSlider {
    background: url("images/assets/preview_arrow.png") no-repeat left 20% center;
    display: flex;
    justify-content: center;
}

.btn-pay-now {
    padding: 0.82rem 2.8rem 0.725rem;
}

.shopping {
    margin-bottom: 30px;
}

.shopping img {
    margin: 0px;
    transform: rotate(180deg);
}

.checkout-summary .clearall {
    font-size: 20px;
    color: #e03636;
    text-decoration: underline;
}

.checkout-summary .clearall img {
    width: 18px;
}

.section.ordertabel {
    padding: 70px 0px;
}

.orderbox {
    background: #f7f7f7;
    border-radius: 30px;
    padding: 48px;
    margin-bottom: 40px;
}

.orderbox.ordertimeline {
    margin-bottom: 0px;
}

.userbookdetail p {
    margin-bottom: 0px;
}

.table-cart table tbody th:last-child {
    text-align: right;
    padding-right: 30px;
}

section.ordertabel {
    padding: 70px 0px;
}

.ordertabel .table-bordered th {
    background: #d1ecf7;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.ordertabel a.btn.btn-md.btn-block.btn-primary.boy-btn {
    width: 182px;
    font-size: 20px;
    font-weight: 400;
    padding: 4px 16px;
}

.bookImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, .8);
}

.bookImage2 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, .8);
}

.demoPage.--right .bookImage {
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, .8);
}

#ajaxLoader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.2);
}

#ajaxLoader span {
    width: 250px;
    background: #fff;
    border-radius: 5px;
    margin: 0 auto;
    float: none;
    padding: 20px;
    display: inherit;
    margin-top: 10%;
    font-size: 18px;
}

.arrowiconS {
    text-align: center;
    margin: 10px auto 30px;
}

.arrowiconS div {
    display: inline;
    cursor: pointer;
}

.arrowiconS .first-pg img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.bookSlider {
    background: url("images/assets/preview_arrow.png") no-repeat left 20% center;
    display: flex;
    justify-content: center;
}

.btn-pay-now {
    padding: 0.82rem 2.8rem 0.725rem;
}

.shopping {
    margin-bottom: 30px;
}

.shopping img {
    margin: 0px;
    transform: rotate(180deg);
}

.checkout-summary .clearall {
    font-size: 20px;
    color: #e03636;
    text-decoration: underline;
}

.checkout-summary .clearall img {
    width: 18px;
}

.section.ordertabel {
    padding: 70px 0px;
}

.orderid {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.ordertabel h2.title-text.title-text-lg {
    display: block;
    line-height: normal;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
}

.userbookdetail p {
    margin-bottom: 0px;
}

.table-cart table tbody th:last-child {
    text-align: right;
    padding-right: 30px;
}

.btn-success {
    background-color: #d1ecf7;
    border-color: #d1ecf7;
    color: #5b5b5b;
}

.ordertabel .table-bordered th {
    background: #d1ecf7;
    color: #585858;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.ordertabel a.btn.btn-md.btn-block.btn-primary.boy-btn {
    width: 182px;
    font-size: 20px;
    font-weight: 400;
    padding: 4px 16px;
}

.ordertabel a.btn.btn-md.btn-block.btn-secondary.boy-btn {
    width: 182px;
    font-size: 20px;
    font-weight: 400;
    padding: 4px 16px;
}

/*
  --------------------------------------
   MEDIA QUERIRES
  --------------------------------------
  */
@media only screen and (min-width: 768px) {
    body {
        padding-top: 0 !important;
    }

    .process-item {
        max-width: 280px;
    }

    .aboutus-content p {
        margin-bottom: 0;
    }

    .explore-content {
        padding-right: 46px;
    }

    .browse-books {
        padding-top: 44px;
    }

    .name-book-wrap {
        padding-left: 33px;
    }

    .name-book-summary > div:first-child {
        padding-right: 23px;
    }

    .name-book-summary > div:last-child {
        padding-left: 24px;
    }

    .book-preview .title {
        margin-bottom: 17px !important;
    }

    .btn-preview,
    .btn-clear {
        padding: 0.845rem 2.45rem;
    }

    .btn-preview {
        background: #2d6fa3 !important;
        border-color: #2d6fa3 !important;
        color: #fff;
        padding: 8px 20px;
        font-size: 24px;
    }

    .checkout-summary {
        max-width: 551px;
        padding-left: 25px !important;
    }

    .checkout-form {
        max-width: 648px;
        padding-right: 25px !important;
    }
}

@media only screen and (min-width: 992px) {
    .aboutus-content {
        padding-left: 50px;
    }

    .promo-text {
        line-height: 80px;
        margin-bottom: 28px;
        font-size: 2.7rem !important;
    }

    .selection-module {
        width: 467px;
        max-width: 100%;
    }

    .book-preview-carousel.owl-carousel .owl-nav > button[class*="owl-"] {
        position: absolute;
        left: -11%;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }

    .book-preview-carousel.owl-carousel .owl-nav > button.owl-next {
        left: auto;
        right: -11%;
    }

    .col-custom-w {
        max-width: 295px;
    }
}

@media (min-width: 1280px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1230px;
    }
}

@media only screen and (min-width: 1200px) {
    .book-card-item {
        padding-right: 34px;
    }

    .book-card-item:nth-child(3n) {
        padding-right: 15px;
    }

    .checkout-summary {
        padding-left: 61px !important;
    }

    .checkout-form {
        padding-right: 78px !important;
    }

    .book-preview div[class*="container"]:after {
        bottom: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .aboutus-content {
        padding-left: 20px;
    }

    .explore-content {
        padding-right: 20px;
    }

    .info-module .col-md-auto {
        max-width: 50%;
    }

    .page-header.custom-title {
        min-height: 180px;
    }

    .page-header.custom-title .page-title {
        font-size: 42px;
        line-height: 38px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .boy-btn,
    .girl-btn {
        padding: 0.535rem 2.26rem;
    }

    .banner-wrapper .carousel-control-prev img,
    .banner-wrapper .carousel-control-next img {
        width: 10px;
    }

    .banner-wrapper .carousel-control-prev,
    .banner-wrapper .carousel-control-next {
        height: 50px;
        width: 50px;
        left: 1rem;
    }

    .banner-wrapper .carousel-control-next {
        right: 1rem;
        left: auto;
    }

    .banner-sub-title {
        font-size: 20px;
    }

    .banner-caption {
        top: 5px;
    }

    .btn {
        font-size: 1.2rem;
    }

    .footer-logo-widget img {
        max-width: 100%;
    }

    .book-preview {
        margin-bottom: 60px;
        padding-top: 30px;
    }

    .btn-pay-now {
        padding: 0.82rem 1.75rem 0.725rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
    .btn-md {
        padding: 0.765rem 1rem;
    }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .book-preview div[class*="container"]::after {
        width: 100px;
        height: 80px;
        background-size: 100%;
        left: 30px;
        z-index: 9;
        bottom: -110px;
    }

    .plane-img img {
        width: 80%;
    }
}

@media only screen and (max-width: 991.5px) {
    .navigation-wrapper {
        padding: 0 15px;
    }

    #mainNavigation.navbar-collapse,
    #mainNavigation.collapse:not(.show) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 300;
        height: 100vh;
        overflow: auto;
        background-color: var(--light);
        width: 60%;
        padding: 1rem;
        display: block;
        visibility: hidden;
        transform: translate(-100%, 0);
        -webkit-transform: translate(-100%, 0);
        transition: all 0.35s ease-in-out 0s;
        -webkit-transition: all 0.35s ease-in-out 0s;
        -moz-transition: all 0.35s ease-in-out 0s;
        -ms-transition: all 0.35s ease-in-out 0s;
        -o-transition: all 0.35s ease-in-out 0s;
    }

    #mainNavigation.show {
        transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        visibility: visible;
    }

    .navigation-wrapper .navbar-nav > .nav-item > .nav-link {
        color: #5b5b5b;
        padding: 0.5rem 0.25rem;
        display: inline-block;
    }

    .page-header {
        background-position: center center;
        background-size: cover;
        min-height: auto;
        padding: 25px 0;
    }

    .book-preview div[class*="container"]::after {
        width: 90px;
        height: 80px;
        background-size: 100%;
    }

    .header,
    .header * {
        max-height: inherit;
    }

    .promo-section {
        min-height: 100%;
        padding-top: 55px;
        padding-bottom: 25px;
        margin-bottom: 30px;
    }

    .book-preview-carousel.owl-carousel .owl-nav {
        display: flex;
        justify-content: center;
    }

    .book-card-item {
        margin-bottom: 40px;
    }

    .name-book-card {
        max-width: 100%;
    }

    .page-header.custom-title {
        min-height: 128px;
    }

    .page-header.custom-title .page-title {
        font-size: 35px;
    }
}

@media only screen and (max-width: 767px) {
    html {
        font-size: 15px;
    }

    .cart-link .badge {
        height: 20px;
        width: 20px;
        line-height: 16px;
        font-size: 0.8rem;
    }

    .header {
        position: fixed !important;
        left: 0;
        top: 0;
        width: 100%;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
        z-index: 100;
    }

    .navbar-brand {
        max-width: 100px;
    }

    .banner-wrapper .carousel-item img {
        min-height: 243px;
        object-fit: contain !important;
        object-position: center;
    }

    .process-item {
        padding-left: 80px;
        padding-bottom: 20px;
    }

    .process-item::after {
        left: 15px;
        bottom: auto;
        height: 100%;
        width: 13px;
        z-index: -1;
        top: 0;
    }

    .process-item:first-child::after {
        border-radius: 25px 25px 0 0;
    }

    .process-item:last-child::after {
        border-radius: 0 0 25px 25px;
        left: 15px;
        right: auto;
        top: -20px;
    }

    .process-card {
        border-radius: 10px !important;
    }

    .process-card::before {
        bottom: auto;
        left: -15px;
        top: 50%;
        transform: translate(0, -50%) rotate(136deg);
    }

    .process-card::after {
        bottom: auto;
        left: -74px;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0;
    }

    .arrow-down {
        position: absolute;
        top: 55%;
        bottom: auto;
        left: -14px;
        transform: translateY(-50%) rotate(-30deg);
        z-index: -1;
    }

    .process-number {
        position: absolute;
        background-color: #000;
        background-image: none;
        height: 40px;
        width: 40px;
        left: -77px;
        font-size: 1.5rem !important;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
    }

    .process-card {
        border-radius: 10px !important;
        box-shadow: -2px -2px 3px rgba(255, 255, 255, 0.5) inset;
        min-height: 90px;
    }

    .process-title {
        line-height: 26px;
        font-size: 22px !important;
        margin-bottom: 0;
    }

    .process-card .card-body {
        padding: 15px !important;
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .process-item:nth-child(1) .process-card .process-number {
        background-color: #05ce96;
    }

    .process-item:nth-child(2) .process-card .process-number {
        background-color: #36b20d;
    }

    .process-item:nth-child(3) .process-card .process-number {
        background-color: var(--info);
    }

    .process-item:nth-child(4) .process-card .process-number {
        background-color: #f0f1e3;
    }

    .footer {
        background: #e5f8fc;
        background-size: cover;
        background-position: 0 top;
    }

    .footer-logo-widget,
    .footer-widget {
        text-align: left;
    }

    .footer-widget-title {
        font-size: 22px !important;
    }

    .copyright p {
        color: #5b5b5b !important;
    }

    .checkout-module::after {
        display: none;
    }

    .banner-wrapper .carousel-control-prev,
    .banner-wrapper .carousel-control-next {
        display: none !important;
    }

    h2.banner-title {
        font-size: 30px;
    }

    .banner-caption {
        top: 10px;
    }

    h4.banner-sub-title {
        font-size: 14px;
    }

    .boy-btn,
    .girl-btn {
        padding: 0.4rem 2.26rem;
    }

    .browse-book-carousel.owl-carousel .owl-nav > button.owl-next {
        right: -3px;
    }

    .browse-book-carousel.owl-carousel .owl-nav > button[class*="owl-"] {
        height: 35px;
        width: 35px;
        line-height: 35px;
        padding: 0;
    }

    .page-header,
    .checkout-header.page-header {
        min-height: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 95px;
    }

    .plane-img {
        top: 0;
    }

    .plane-img img {
        width: 80px;
    }

    .book-card-item {
        margin-bottom: 30px;
    }

    .promo-section {
        margin-bottom: 30px;
    }

    .aboutus-wrapper {
        padding-top: 20px !important;
    }

    .browse-books .tab-content {
        padding-bottom: 0;
    }

    .book-card {
        padding: 0 15px;
    }

    .book-card-item:nth-last-child {
        margin-bottom: 0;
    }

    .btn img {
        width: 30px;
    }

    .name-book-wrapper {
        padding-top: 95px;
        padding-bottom: 20px;
    }

    .book-preview {
        margin-top: 0;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 40px;
        background: #e5f8fc;
        background: -moz-linear-gradient(top,
                #e5f8fc 0%,
                #ffffff 50%,
                #e5f8fc 100%);
        background: -webkit-linear-gradient(top,
                #e5f8fc 0%,
                #ffffff 50%,
                #e5f8fc 100%);
        background: linear-gradient(to bottom,
                #e5f8fc 0%,
                #ffffff 50%,
                #e5f8fc 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5f8fc', endColorstr='#e5f8fc', GradientType=0);
    }

    .book-preview::before {
        top: auto;
        bottom: -25px;
    }

    .book-preview div[class*="container"]::after {
        width: 90px;
        height: 80px;
        background-size: 100%;
        top: auto;
        bottom: -100px;
    }

    .btn {
        padding: 0.535rem 1.5rem;
        font-size: 1.25rem;
    }

    .btn-fix {
        min-width: 157px;
        padding: 0.535rem 0.95rem;
    }

    .previewBook-header .page-title {
        line-height: 38px;
    }

    .previewBook-warpper {
        padding-top: 20px;
    }

    .help-wrapper {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .checkout-form {
        padding-right: 0 !important;
    }

    .checkout-summary {
        padding-left: 0 !important;
    }

    .checkout-summary::after {
        width: auto;
        left: -15px;
        right: -15px;
        top: -15px;
        bottom: -20px;
    }

    .table-mobile-responsive table thead th {
        display: none;
        width: 100% !important;
    }

    .table-mobile-responsive table tbody tr:not(:first-child) {
        border-top: 2px solid #e2e2e2;
    }

    .table-mobile-responsive table tbody td {
        display: block;
        text-align: left;
        position: relative;
        padding: 10px !important;
        width: 100%;
        border: 0;
    }

    .table-mobile-responsive table tbody td:before {
        content: attr(title);
        width: 100%;
        display: block;
        color: #5b5b5b;
        font-size: 20px;
        font-weight: bold;
    }

    .table-mobile-responsive table tfoot th {
        width: 50%;
        float: left;
    }

    .banner-wrapper {
        padding-top: 95px;
    }

    .browse-book-item {
        max-width: 100%;
    }

    .why-us-wrapper .image-module img {
        width: 100%;
    }

    .subscribe_now .subscribe_view {
        grid-template-columns: 1fr;
        padding: 20px;
    }

    .subscribe_now .subscribe_view .subscribe_content {
        margin-bottom: 20px;
        text-align: center;
    }

    .subscribe_now .subscribe_view .subscibe_form {
        padding-left: 0px;
    }

    .subscribe_now .subscribe_view .subscibe_form form .payment-button-box button {
        padding: 0 15px;
        height: 52px;
    }

    .browse-books #books_all {
        padding-bottom: 10px;
    }

    .book-card .card-header {
        padding: 20px 0 20px;
    }

    .book-card .card-footer {
        padding: 0 0 20px;
    }

    .info-module .col-md-auto {
        max-width: 100%;
    }

    .aboutus-wrapper .info-module .fancy-card img {
        width: 100%;
    }

    .page-header.custom-title .page-title {
        font-size: 30px;
        margin-top: 20px;
    }

    .checkout-module {
        padding-top: 30px;
    }

    label {
        margin-bottom: 5px;
    }

    .checkout-form .form-group {
        margin-bottom: 18px;
    }

    .footer-logo-widget img {
        max-width: 150px;
    }

    .auth_section {
        margin-top: 250px;
    }

    section.ordertabel {
        padding: 40px 0px;
        margin-top: 95px;
    }

    .search-box .dropdown-menu {
        left: auto;
        right: -200px;
    }

    .search-box .dropdown-menu .search input {
        height: 42px;
    }

    .orderbox {
        padding: 20px;
        margin-bottom: 30px;
    }

    .ordertabel h2.title-text.title-text-lg {
        margin-bottom: 10px;
    }

    .orderbox .orderid h3 {
        font-size: 20px;
        word-break: break-all;
    }

    .orderbox .orderid h3.price {
        white-space: nowrap;
    }

    .orderbox.ordertimeline .book-image {
        margin-bottom: 15px;
    }

    .book-summary {
        padding: 5px;
    }

    .book-summary .row-devider {
        justify-content: center;
    }
}

@media only screen and (min-width: 590px) {
    .btn-fix {
        min-width: 250px;
        padding: 0.85rem 2.5rem;
    }
}

@media only screen and (max-width: 575px) {
    .navigation-wrapper {
        padding: 0 5px;
    }

    .hi-user a {
        font-size: 16px;
    }

    .other-elem img,
    .navbar-toggler-icon {
        width: 20px;
    }

    .hi-user {
        margin-left: 8px !important;
    }

    .cart-box {
        margin-right: 8px !important;
    }

    .selection-module-wrapper .selection-module:nth-child(1),
    .selection-module-wrapper .selection-module:nth-child(3) {
        order: -1;
    }

    .selection-module-wrapper .selection-module:nth-child(3) {
        margin-bottom: 30px !important;
    }

    .book-preview {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .footer-logo-widget img {
        max-width: 120px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 766px) {
    .btn-md {
        padding: 0.765rem 1rem;
    }
}

@media only screen and (max-width: 375px) {
    .subscribe_now .subscribe_view .subscibe_form form {
        flex-direction: column;
    }

    .subscribe_now .subscribe_view .subscibe_form form .form-group {
        margin-bottom: 15px;
    }

    .subscribe_now .subscribe_view .subscibe_form form .payment-button-box button {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0px;
    }
}

.book_description {
    height: 80px;
    overflow: hidden;
}

.book_description1 {
    height: auto;
    overflow: hidden;
}

.dropdown {
    width: 15%;
    position: relative;
    color: white;
}

.dropdown .label {
    color: #000;
    margin-bottom: 5px;
    font-family: 'Ballooning', sans-serif;
}

.dropdown .select {
    align-items: center;
    background-color: #f0f1e3;
    border: 0;
    border-radius: .625rem;
    box-shadow: 5px 4px 0 #0000001c;
    color: #5b5b5b;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    font-size: 1.563rem;
    font-family: Bubblegum Sans, sans-serif;
    justify-content: space-between;
    padding: 15px;
    transition: .3s;
}

.dropdown .select .selected {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdown .select .selected.placeholder {
    color: #fff;
    font-family: 'Ballooning', sans-serif;
}

.dropdown .caret {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid white;
    transition: 0.3s;
}

.menu {
    visibility: hidden;
    background-color: #f0f1e3;
    border-radius: 0.625rem;
    overflow: hidden;
    position: absolute;
    padding-left: 0px;
    width: 100%;
    top: 72%;
    list-style: none;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s;
    z-index: 1;
    margin-left: 0px;
}

.menu li {
    cursor: pointer;
    padding: 10px 15px;
    font-size: 20px;
    font-family: 'Ballooning', sans-serif;
}

.menu li:hover {
    background-color: #f0f1e3;
}

.menu.menu-open {
    visibility: visible;
    opacity: 1;
}


.caret-rotate {
    transform: rotate(180deg);
}

/* .active {
	background-color: black;
} */

#gender2 option:checked {
    background-color: rgb(9, 9, 9);
    color: white;
}

#gender2 option:hover {
    background-color: #eb9292;
}


.fade-out {
    opacity: 0.5;
    /* You can adjust the opacity as per your preference */
    transition: opacity 0.5s ease;
    /* Adjust the transition duration as needed */
    pointer-events: none;
    /* Disable pointer events while fading out */
}

.header .select {
    width: 100px;
    height: 40px;
    border: 2px solid #000;
    position: relative;
    border-radius: 7px;
}

.header .select::before {
    content: '|';
    position: absolute;
    right: 16px;
    /* Adjust this value to position the separator */
    top: 50%;
    transform: translateY(-50%);
    color: #5b5b5b;
    /* Color of the separator */
    font-size: 16px;
    /* Adjust font size as needed */
    padding: 0 5px;
    /* Adjust padding as needed */
}

.navbar-light .navbar-nav .nav-item {
    padding: 8px 10px !important;
}

@font-face {
    font-family: 'PuddyGm-regular';
    src: url("fonts/PuddyGum-Regular.ttf");
}

.evolve-bg {
    background: url("images/background/evolve-bg.png");
  
    background-size: cover;
    background-position: center;
    position: relative;
    height: 507px;
    background-repeat: no-repeat;
}

.evolve-sec {
    background: #eaf0e2;
    padding: 150px 0 55px;
    background: linear-gradient(180deg, #eaf0e2, #fff) !important;
}

.evolve-bg .text-1 {
    background: #c4e7af;
    border: 2px solid #fff0;
    border-radius: 15px;
    box-shadow: 0 0 24px 0 #dadada;
    color: #5d5d5d;
    font-family: Schoolbell, cursive;
    left: -76%;
    padding: 6px 6px 0;
    position: absolute;
    top: 26%;
    transition: transform .2s;
    transition: .5s ease-in-out;
    width: 393px;
}

.evolve-bg .text-1 h2,
.evolve-bg .text-3 h2,
.evolve-bg .text-5 h2 {
    color: #5d5d5d;

}

.evolve-bg .text-1 p,
.evolve-bg .text-3 p,
.evolve-bg .text-5 p {
    color: #5d5d5d;
}

.evolve-bg .text-1 a,
.evolve-bg .text-3 a,
.evolve-bg .text-5 a {
    color: #5d5d5d;
}

.evolve-bg .text-2 {
    position: absolute;
    border: 2px solid #fff0;
    padding: 6px 6px 0px;
    transition: transform .2s;
    box-shadow: 0px 0px 24px 0px #dadada;
    left: -66%;
    top: 74%;
    width: 415px;
    background: #2d6fa1;
    transition: 0.5s ease-in-out;
    color: #fff;
    font-family: "Schoolbell", cursive;
    border-radius: 15px;
}
.evolve-bg .text-3 {
    border: 2px solid #fff0;
    position: absolute;
    padding: 6px 6px 0px;
    transition: transform .2s;
    box-shadow: 0px 0px 24px 0px #dadada;
    right: -54%;
    background: #ffe882;
    top: 74%;
    width: 398px;
    transition: 0.5s ease-in-out;
    color: #5d5d5d;
    font-family: "Schoolbell", cursive;
    border-radius: 15px;
}
.evolve-des h3, .evolve-imp h3 {
    color: #2d6fa3;
    font-family: Bubblegum Sans, sans-serif;
    font-size: 40px;
    margin-bottom: 25px;
    margin-top: 40px;
}

.evolve-des, .evolve-imp {
    padding: 50px 0px;
}
.evolve-img img{
    width: 100%;
}
.evolve-des p {
    font-family: 'Schoolbell';
    margin-bottom: 9px;
    font-size: 20px;
}

.evolve-des {
    background: #eaf0e2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.evolve-bg .text-4 {
    width: 417px;
    position: absolute;
    border: 2px solid #fff0;
    padding: 6px 6px 0px;
    transition: transform .2s;
    box-shadow: 0px 0px 24px 0px #dadada;
    right: -71%;
    background: #3b5331;
    top: 31%;
    font-family: "Schoolbell", cursive;
    transition: 0.5s ease-in-out;
    color: #fff;
    border-radius: 15px;
}
.evolve-bg .text-5 {
    position: absolute;
    border: 2px solid #fff0;
    transition: transform .2s;
    box-shadow: 0px 0px 24px 0px #dadada;
    background: #d4ecf8;
    font-family: "Schoolbell", cursive;
    transition: 0.5s ease-in-out;
    color: #5d5d5d;
    border-radius: 15px;
    top: -27%;
    width: 383px;
    left: 91%;
    padding: 6px 6px 0px;
}
.evolve-bg .text-6 {
    background: #fea928;
    border: 2px solid #fff0;
    border-radius: 15px;
    box-shadow: 0 0 24px 0 #dadada;
    color: #fff;
    font-family: Schoolbell, cursive;
    left: -62%;
    padding: 6px 6px 0px;
    position: absolute;
    top: -21%;
    transition: transform .2s;
    transition: .5s ease-in-out;
    width: 435px;
}
.evolve-bg h2 {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
}

.evolve-bg p {
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 10px;
    font-family: "Schoolbell", cursive;
}

.evolve-bg p a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 600;
}

.evolve-bg .image-1 .icon {
    width: 90px;
    position: absolute;
    top: 24%;
    left: 15%;
    transition: transform .2s;
}

.evolve-bg .image-2 .icon {
    width: 80px;
    position: absolute;
    top: 55%;
    left: 16%;
    transition: transform .2s
}

.evolve-bg .image-3 .icon {
    width: 90px;
    position: absolute;
    left: 43%;
    top: 69%;
    transition: transform .2s;
}

.evolve-bg .image-4 .icon {
    width: 90px;
    position: absolute;
    top: 55%;
    left: 69%;
    transition: transform .2s;
}

.evolve-bg .image-5 .icon {
    width: 90px;
    position: absolute;
    top: 25%;
    left: 70%;
    transition: transform .2s;
}

.evolve-bg .image-6 .icon {
    width: 90px;
    position: absolute;
    top: 9%;
    left: 42%;
    transition: transform .2s;
}
.evolve-bg .image-1::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 25%;
    background-size: contain;
    left: -101px;
     background: url("images/assets/arrow.png"); 
    
    height: 133px;
    width: 182px;
    transform: rotate(194deg);
}


.evolve-bg .image-2::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 382px;
    background-size: contain;
    left: -20px;
     background: url("images/assets/arrow.png"); 
   
    width: 180px;
    height: 96px;
    transform: rotate(214deg);
}


.evolve-bg .image-3::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 80%;
    background-size: contain;
    left: 350px;
     background: url("images/assets/arrow.png"); 
    
    height: 114px;
    width: 190px;
    transform: rotate(68deg);
}

.evolve-bg .image-4::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 52%;
    background-size: contain;
    right: -59px;
     background: url("images/assets/arrow.png"); 
   
    height: 114px;
    width: 190px;
    transform: rotate(13deg);
}

.evolve-bg .image-5::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 4%;
    background-size: contain;
    right: -81px;
    background: url("images/assets/arrow.png");
    
    height: 114px;
    width: 204px;
    transform: rotate(350deg);
}

.evolve-bg .image-6::before {
    z-index: 1;
    content: '';
    position: absolute;
    background-size: contain;
    height: 116px;
    right: 62%;
    top: -7%;
     background: url("images/assets/arrow.png"); 
    width: 177px;
    transform: rotate(227deg);
}

.evolve-bg .image-1:hover .text-1,
.evolve-bg .image-2:hover .text-2,
.evolve-bg .image-3:hover .text-3,
.evolve-bg .image-4:hover .text-4,
.evolve-bg .image-5:hover .text-5,
.evolve-bg .image-6:hover .text-6 {
    box-shadow: 0px 0px 30px 0px #aaaaaa;
    border: 2px solid white;
}

.evolve-bg .image-1:hover a img,
.evolve-bg .image-2:hover a img,
.evolve-bg .image-3:hover a img,
.evolve-bg .image-4:hover a img,
.evolve-bg .image-5:hover a img,
.evolve-bg .image-6:hover a img {
    transform: scale(1.1);
}

@media(max-width:991px) {
    .evolve-bg {
      
        background-size: contain;
        background-position: center;
        position: relative;
        height: 110px;
        background-repeat: no-repeat;
    }

    .evolve-bg .image-1 .icon {
        width: 19px;
        position: absolute;
        top: 24%;
        left: 38%;
        transition: transform .2s;
    }

    .evolve-bg .image-1::before {
        z-index: 1;
        content: '';
        position: absolute;
        background-repeat: no-repeat !important;
        top: -64%;
        background-size: contain !important;
        left: 5px;
        background: url("images/assets/arrow.png");
       
        height: 34px;
        width: 183px;
        transform: rotate(252deg);
    }

    .evolve-bg .image-3 .icon {
        width: 20px;
        position: absolute;
        top: 68%;
        left: 47%;
        transition: transform .2s;
    }

    .evolve-bg .text-1 {
        width: 114px;
        position: absolute;
        border: 2px solid #fff0;
        padding: 6px 6px 0px;
        transition: transform .2s;
        box-shadow: 0px 0px 24px 0px #dadada;
        left: -2%;
        font-size: 5px;
        background: #c4e7af;
        top: -21%;
        transition: 0.5s ease-in-out;
        color: #5d5d5d;
        font-family: "Schoolbell", cursive;
        border-radius: 15px;
    }

    .evolve-bg h2 {
        font-size: 6px;
        font-weight: 700;
        margin-bottom: 2px;
        color: #ffffff;
    }

    .evolve-bg p {
        color: #ffffff;
        font-size: 4px;
        margin-bottom: 10px;
        font-family: "Schoolbell", cursive;
    }

.evolve-bg .text-2 {
        width: 110px;
        position: absolute;
        border: 2px solid #fff0;
        padding: 6px 6px 0px;
        transition: transform .2s;
        box-shadow: 0px 0px 24px 0px #dadada;
        left: 3%;
        background: #2d6fa1;
        top: 62%;
        font-size: 5px;
        transition: 0.5s ease-in-out;
        color: #fff;
        font-family: "Schoolbell", cursive;
        border-radius: 15px;
    }
    .evolve-bg .image-2::before {
        z-index: 1;
        content: '';
        position: absolute;
        background-repeat: no-repeat !important;
        top: -21%;
        background-size: contain !important;
        left: 31%;
        background: url("images/assets/arrow.png");
        height: 133px;
        width: 34px;
        transform: rotate(174deg);
    }

    .evolve-bg .image-2 .icon {
        width: 17px;
        position: absolute;
        top: 55%;
        left: 39%;
        transition: transform .2s;
    }

    .evolve-bg .text-3 {
        width: 126px;
        border: 2px solid #fff0;
        position: absolute;
        padding: 6px 6px 0px;
        transition: transform .2s;
        box-shadow: 0px 0px 24px 0px #dadada;
        right: 17px;
        font-size: 5px;
        background: #ffe882;
        top: 85%;
        transition: 0.5s ease-in-out;
        color: #5d5d5d;
        font-family: "Schoolbell", cursive;
        border-radius: 15px;
    }

    .evolve-bg .image-3::before {
        z-index: 1;
        content: '';
        background-repeat: no-repeat !important;
        position: absolute;
        top: 144%;
        background-size: contain !important;
        left: 128px;
        background: url("images/assets/arrow.png");
        height: 32px;
        width: 174px;
        transform: rotate(73deg);
    }
    .evolve-bg .image-4 .icon {
        width: 17px;
        position: absolute;
        top: 56%;
        left: 57%;
        transition: transform .2s;
    }
.evolve-bg .text-4 {
        width: 114px;
        position: absolute;
        border: 2px solid #fff0;
        padding: 6px 6px 0px;
        font-size: 5px;
        transition: transform .2s;
        box-shadow: 0px 0px 24px 0px #dadada;
        right: 0;
        background: #3b5331;
        top: 20%;
        font-family: "Schoolbell", cursive;
        transition: 0.5s ease-in-out;
        color: #fff;
        border-radius: 15px;
    }

    .evolve-bg .image-4::before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 104%;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        right: -32px;
        background: url("images/assets/arrow.png");
        height: 33px;
        width: 190px;
        transform: rotate(51deg);
    }
    .evolve-bg .image-5 .icon {
        width: 21px;
        position: absolute;
        top: 24%;
        left: 56%;
        transition: transform .2s;
    }

    .evolve-bg .text-5 {
        width: 127px;
        position: absolute;
        border: 2px solid #fff0;
        padding: 6px 6px 0px;
        transition: transform .2s;
        box-shadow: 0px 0px 24px 0px #dadada;
        left: 64%;
        font-size: 6px;
        background: #d4ecf8;
        top: -61%;
        font-family: "Schoolbell", cursive;
        transition: 0.5s ease-in-out;
        color: #5d5d5d;
        border-radius: 15px;
    }

    .evolve-bg .image-5::before {
        z-index: 1;
        content: '';
        position: absolute;
        top: -2%;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        right: 94px;
        background: url("images/assets/arrow.png");
        height: 114px;
        width: 37px;
        transform: rotate(350deg);
    }
    .evolve-bg .text-6 {
        width: 131px;
        position: absolute;
        border: 2px solid #fff0;
        padding: 6px 6px 0px;
        font-size: 6px;
        transition: transform .2s;
        box-shadow: 0px 0px 24px 0px #dadada;
        left: 26%;
        background: #fea928;
        top: -98%;
        font-family: "Schoolbell", cursive;
        transition: 0.5s ease-in-out;
        color: #fff;
        border-radius: 15px;
    }
    .evolve-bg .image-6 .icon {
        width: 20px;
        position: absolute;
        top: 11%;
        left: 47%;
        transition: transform .2s;
    }
    .evolve-bg .image-6::before {
        z-index: 1;
        content: '';
        background-repeat: no-repeat !important;
        position: absolute;
        top: -74%;
        background-size: contain !important;
        right: 31%;
        background: url("images/assets/arrow.png");
        height: 149px;
        width: 41px;
        transform: rotate(272deg);
    }
}

.browseBook-pageHeader h4.page-title {
    font-size: 27px !important;
    line-height: 25px !important;
}

.book-preview h2.title-text {
    font-size: clamp(3.125rem,-1.561rem + 7.5581vw,4.75rem) !important;
}

.evolve-des {
    background: #eaf0e2;
    background-position: 50%;
    background: linear-gradient(180deg, #eaf0e2, #fff) !important;
    background-repeat: no-repeat;
    background-size: cover;
}
.navbar-collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 0.0;
}

.header .other-elem {
    align-items: center;
    justify-content: space-between;
}

.social-networks p{
    display: flex;
    align-items: baseline;
    text-transform: capitalize;
}

.book-summary .media-body .cat-title {
    display: none;
    transition: 0.5s ease-in-out;
}

.book-summary .media-body:hover .cat-title {
    display: block;
    transition: 0.5s ease-in-out;
}
.bg-1 {
    background: #fea928;
    color: #fff;
}

.bg-2 {
    background: #c4e7af;
    color: #5d5d5d;
}

.bg-3 {
    color: white;
    background: #2d6fa1;
}

.bg-4 {
    color: #5d5d5d;
    background: #d4ecf8;
}

.bg-5{
    color: #ffffff;
    background: #3b5331;
}

.bg-6 {
    background: #ffe882;
    color: #5d5d5d;
}

.browse-books .select {
    align-items: center;
    background-color: #f0f1e3;
    border: 0;
    border-radius: .625rem;
    box-shadow: 5px 4px 0 #0000001c;
    color: #5b5b5b;
    cursor: pointer;
    display: flex;
    font-family: inherit;

        font-size: 16px;

    font-weight: 700;
    justify-content: space-between;
     padding: 8px 11px 5px;
line-height: 15px;
    margin-bottom: 30px;
    transition: .3s;
}

.book-label { display: block;
    text-align: center;
    font-size: 18px;
    margin-bottom: 5px;
}

.auth_section .another_page_text .page-subtitle {
    font-size: 24px;
}


.dropdown-menu {
    display: none; /* Remove forced 'show' */
}

.dropdown.show .dropdown-menu {
    display: block;
}
