@font-face {
    font-family: 'Ageta Chubby';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/AgetaChubbyDemo.eot');
    src: url('../fonts/AgetaChubbyDemo.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AgetaChubbyDemo.woff2') format('woff2'),
        url('../fonts/AgetaChubbyDemo.woff') format('woff'),
        url('../fonts/AgetaChubbyDemo.ttf') format('truetype'),
        url('../fonts/AgetaChubbyDemo.svg#../fonts/AgetaChubbyDemo') format('svg');

}
@font-face {
    font-family: 'Ageta Chubby';
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    src: url('../fonts/AgetaChubbyDemo-Italic.eot');
    src: url('../fonts/AgetaChubbyDemo-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AgetaChubbyDemo-Italic.woff2') format('woff2'),
        url('../fonts/AgetaChubbyDemo-Italic.woff') format('woff'),
        url('../fonts/AgetaChubbyDemo-Italic.ttf') format('truetype'),
        url('../fonts/AgetaChubbyDemo-Italic.svg#../fonts/AgetaChubbyDemo-Italic') format('svg');

}
@font-face {
    font-family: 'Schoolbell';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/Schoolbell-Regular.eot');
    src: url('../fonts/Schoolbell-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Schoolbell-Regular.woff2') format('woff2'),
        url('../fonts/Schoolbell-Regular.woff') format('woff'),
        url('../fonts/Schoolbell-Regular.ttf') format('truetype'),
        url('../fonts/Schoolbell-Regular.svg#Schoolbell-Regular') format('svg');
}

